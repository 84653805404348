<template>
  <div class="myDiv">
    <header class="test-title">测试使用</header>

    <div class="test-title" v-if="loading">加载中.....</div>
    <div id="paypal-button-container"></div>
  </div>
</template>

<script>
import { createPaypalOrderApi, capturePaypalOrderApi } from "@/api";
export default {
  components: {},
  props: {},
  data() {
    return {
      loading: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    inits() {
      const id =
        "AbzJPmLGDWxiPPGVfLJGDN5oSKHEl9KG1_t_R83Wkf_3h_lXbhMPBuEv4EfAM9_8lnZYjfBx6D6OloOF";
      const url = `https://.paypal.com/sdk/js?client-id=${id}`;
      this.dynamicLoadJs(url, this.jsCallBack);
    },
    jsCallBack() {
      paypal
        .Buttons({
          createOrder: (data, actions) => {
            // 调用您的服务器来创建订单
            debugger
            return fetch("http://localhost:8080/api/v1/create-paypal-order", {
              method: "POST",
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (orderData) {
                return orderData.id;
              });

            // return createPaypalOrderApi().then((res) => {
            //   return res.data.id;
            // });
          },
          onApprove: function (data, actions) {
            // 捕获订单
            return fetch("http://localhost:8080/api/v1/capture-paypal-order", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderID: data.orderID,
              }),
            })
              .then(function (res) {
                return res.json();
              })
              .then(function (captureData) {
                // 处理成功支付
                console.log("Capture result", captureData);
                // 可以在这里执行其他逻辑，如显示成功消息等
                return capturePaypalOrderApi({ orderID: data.orderID }).then(
              (res) => {
                console.log("Capture result", res);
                alert("支付成功");
              }
            );
              });

            
          },
        })
        .render("#paypal-button-container");
    },
    dynamicLoadJs(url, callback) {
      var head = document.getElementsByTagName("head")[0];
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      if (typeof callback == "function") {
        script.onload = script.onreadystatechange = function () {
          if (
            !this.readyState ||
            this.readyState === "loaded" ||
            this.readyState === "complete"
          ) {
            callback();
            script.onload = script.onreadystatechange = null;
          }
        };
      }
      head.appendChild(script);
    },
  },
  created() {},
  mounted() {
    this.inits();
  },
};
</script>
<style lang="less" scoped>
.myDiv {
  .test-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
 #card-form > div {
    width: 50%;
    height: 40px;
    margin:auto;
    margin-bottom: 20px;
    background-color: antiquewhite;
    color: #409eff;
  }
  button {
    width: 100%;
    height: 40px;
    background-color: #409eff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
}
</style>
